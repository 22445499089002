import { Alert, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../store";
import { isEmpty } from "lodash";

type ErrorToastProps = {
	showErrorToast?: boolean;
	errorMessage?: string | undefined;
	clearError?: () => { type: string };
	error?: { message: string; id: number };
};

export default function ErrorToast({
	errorMessage,
	clearError,
	error,
}: ErrorToastProps) {
	const [open, setOpen] = useState(true);
	const dispatch = useAppDispatch();

	const handleClose = (
		_event: React.SyntheticEvent | Event,
		_reason?: string,
	) => {
		setOpen(false);

		if (clearError) {
			dispatch(clearError);
		}
	};

	useEffect(() => {
		if (!isEmpty(error)) {
			setOpen(true);
		}
	}, [error, error?.id]);

	useEffect(() => {
		if (!isEmpty(errorMessage)) {
			setOpen(true);
		}
	}, [errorMessage]);

	return (
		<>
			<Snackbar
				open={open && (!isEmpty(error?.message) || !isEmpty(errorMessage))}
				autoHideDuration={30000}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				onClose={handleClose}
			>
				<Alert severity="error" variant="filled">
					<Typography variant="body1" color="white">
						{error?.message ? error.message : errorMessage ? errorMessage : ""}
					</Typography>
				</Alert>
			</Snackbar>
		</>
	);
}
