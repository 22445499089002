import * as AIF from "@stateless/aif-api";
import { Zodios, mergeApis } from "@zodios/core";
import { ZodiosHooks } from "@zodios/react";
import axios from "axios";
import { useAuth } from "oidc-react";
import mockData from "./test/mockData.json"; // Import the mock data JSON file
import { interceptAxiosResponseForMockData } from "./test/test-utils";

export type Status = "idle" | "loading";

// Configure default axios
axios.defaults.baseURL = "/api/v1";
axios.defaults.paramsSerializer = { indexes: null };

export const ax = axios.create();

// Enable mock data based on environment variable
const isMockEnabled = import.meta.env.VITE_USE_MOCK_DATA;
// Define the API specs
const apiSpecs = {
	[AIF.OcnNetworks.rootUrl]: AIF.OcnNetworks.Spec,
	[AIF.Services.rootUrl]: AIF.Services.Spec,
	[AIF.Lookup.rootUrl]: AIF.Lookup.Spec,
	[AIF.Accounts.AwsExternal.rootUrl]: AIF.Accounts.AwsExternal.Spec,
	[AIF.Rbac.Organizations.rootUrl]: AIF.Rbac.Organizations.Spec,
	[AIF.FinishOnboard.rootUrl]: AIF.FinishOnboard.Spec,
};
// Map API routes to mock data dynamically based on API specs
const mockDataMap = {
	[AIF.Accounts.AwsExternal.rootUrl]: mockData.awsExternalAccounts.list,
	[AIF.Lookup.rootUrl + "/list-vpcs"]: mockData.lookup.aws_vpcs,
	[AIF.OcnNetworks.rootUrl]: mockData.networks.list,
	// Add other mappings as necessary
};

// Add Axios response interceptor for mock data handling and validation
if (isMockEnabled === "true") {
	interceptAxiosResponseForMockData(ax, mockDataMap, apiSpecs);
}

// Merge APIs and create the Zodios client
export const ocnApiClient = new Zodios(
	mergeApis(apiSpecs),
	{ axiosInstance: ax }, // Pass the axios instance without `requestHandler`
);

// Hook to use API client with auth
export const useOcnHooks = () => {
	const auth = useAuth();
	const configuredInstance = axios.create({
		headers: { Authorization: `Bearer ${auth.userData?.access_token}` },
	});

	// Add mock handling in the hook instance if necessary
	if (isMockEnabled === "true") {
		interceptAxiosResponseForMockData(
			configuredInstance,
			mockDataMap,
			apiSpecs,
		);
	}

	return new ZodiosHooks(
		"ocnApi",
		new Zodios(mergeApis(apiSpecs), { axiosInstance: configuredInstance }),
	);
};
