import {
	ParamParseKey,
	PathMatch,
	matchPath,
	useLocation,
} from "react-router-dom";

/**
 * Given a list of route patterns, return the first one that matches the current path, or null if none of them match.
 */
export function useRouteMatch<
	ParamKey extends ParamParseKey<Path>,
	Path extends string,
>(patterns: readonly ParamKey[]): PathMatch<ParamParseKey<ParamKey>> | null {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}

	return null;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

// Useful for filters
export function isPresent<T>(v: T): v is Exclude<T, null | undefined> {
	return v !== undefined && v !== null;
}

export type NonNullableValues<
	T extends Record<string | number | symbol, unknown>,
> = {
	[K in keyof T]: NonNullable<T[K]>;
};
