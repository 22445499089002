import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ocnApiClient as aifApiClient, Status } from "../api";
import { Services } from "@stateless/aif-api";
import { RootState, useAppDispatch, useAppSelector } from ".";
import { useEffect } from "react";
import { isPresent } from "../utils";

type ServicesState = {
	status: Status;
	services: Array<Services.Get>;
	error?: string;
};

const initialState: ServicesState = {
	services: [],
	status: "idle",
};

export const getServices = createAsyncThunk.withTypes<{ state: RootState }>()(
	"getServices",
	async (_, { getState }) =>
		aifApiClient.listServices({
			headers: { Authorization: `Bearer ${getState().login.jwt}` },
		}),
);

export const ServicesSlice = createSlice({
	name: "Services",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getServices.pending, (state) => {
				state.status = "loading";
				console.log("getServices pending");
			})
			.addCase(getServices.fulfilled, (state, response) => {
				state.status = "idle";
				if (response.payload) {
					state.services = response.payload.data;
				}
				console.log("getServices fulfilled", state, response);
			})
			.addCase(getServices.rejected, (state, response) => {
				state.status = "idle";
				state.error = response.error.message;
				console.log("getServices error", state, response);
			});
	},
});
export default ServicesSlice.reducer;

export const useServiceList = (): ServicesState => {
	const serviceState = useAppSelector((state) => state.services);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (
			serviceState.services.length === 0 &&
			!isPresent(serviceState.error) &&
			serviceState.status === "idle"
		) {
			dispatch(getServices());
		}
	}, [dispatch, serviceState]);

	return serviceState;
};
