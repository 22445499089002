import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Link as MuiLink,
} from "@mui/material";
import { Stack } from "@mui/system";
import { TextFieldElement, useForm } from "react-hook-form-mui";
import { Link } from "react-router-dom";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../store";
import { CreateAwsAccount, fetchAwsAccounts } from "../store/awsAccounts";
import { useNavigate } from "react-router-dom";
import ErrorToast from "./errorToast";

const genTemplate = (externalOrgId: string) =>
	JSON.stringify({
		AWSTemplateFormatVersion: "2010-09-09",
		Resources: {
			AifServiceAccountRole: {
				Type: "AWS::IAM::Role",
				Properties: {
					AssumeRolePolicyDocument: {
						Version: "2012-10-17",
						Statement: [
							{
								Effect: "Allow",
								Principal: {
									AWS: "arn:aws:iam::873016339377:root",
								},
								Action: ["sts:AssumeRole"],
								Condition: {
									StringEquals: {
										"sts:ExternalId": externalOrgId,
									},
								},
							},
						],
					},
					Policies: [
						{
							PolicyName: "AifServiceAccountPermissions",
							PolicyDocument: {
								Version: "2012-10-17",
								Statement: [
									{
										Effect: "Allow",
										Action: [
											"aws-marketplace:BatchMeterUsage",
											"aws-marketplace:ResolveCustomer",
											"ec2:AuthorizeSecurityGroupEgress",
											"ec2:AuthorizeSecurityGroupIngress",
											"ec2:CreateSecurityGroup",
											"ec2:CreateTags",
											"ec2:CreateVpcEndpoint",
											"ec2:DeleteSecurityGroup",
											"ec2:DeleteVpcEndpoints",
											"ec2:DescribeNetworkInterfaces",
											"ec2:DescribePrefixLists",
											"ec2:DescribeSecurityGroupRules",
											"ec2:DescribeSecurityGroups",
											"ec2:DescribeSubnets",
											"ec2:DescribeVpcAttribute",
											"ec2:DescribeVpcEndpointConnections",
											"ec2:DescribeVpcEndpoints",
											"ec2:DescribeVpcs",
											"ec2:ModifySecurityGroupRules",
											"ec2:RevokeSecurityGroupEgress",
											"ec2:RevokeSecurityGroupIngress",
											"route53:AssociateVPCWithHostedZone",
											"route53:ChangeResourceRecordSets",
											"route53:CreateHostedZone",
											"route53:DeleteHostedZone",
											"route53:GetChange",
											"route53:GetHostedZone",
											"route53:ListHostedZones",
											"route53:ListResourceRecordSets",
											"route53:ListTagsForResource",
											"route53:ListTagsForResources",
										],
										Resource: "*",
									},
								],
							},
						},
					],
				},
			},
		},
		Outputs: {
			ExternalOrgId: {
				Value: externalOrgId,
			},
			RoleARN: {
				Value: {
					"Fn::GetAtt": ["AifServiceAccountRole", "Arn"],
				},
			},
		},
	});

const FormSchema = z.object({
	name: z
		.string()
		.min(1)
		.transform((str) => str.trim()),
	external_id: z
		.string()
		.min(1)
		.transform((str) => str.trim()),
	role_arn: z
		.string()
		.min(1)
		.transform((str) => str.trim()),
});
type FormSchema = z.infer<typeof FormSchema>;

export default function AwsAccountSetupModal() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { error } = useAppSelector((state) => state.awsAccounts);

	const { handleSubmit, control, formState } = useForm({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			name: "",
			external_id: "",
			role_arn: "",
		},
	});

	const onSuccess = (formData: FormSchema) => {
		// Dispatch a Create
		// Get an ID back from the create
		// Submit it to the Account Setup store
		dispatch(
			CreateAwsAccount({
				account: formData,
				onSuccess: () => {
					dispatch(fetchAwsAccounts());
					navigate("/aifabric/create");
				},
			}),
		);
	};

	const onError = (err: unknown) => {
		console.error(err);
	};

	return (
		<>
			{error ? (
				<ErrorToast showErrorToast={true} errorMessage={error}></ErrorToast>
			) : null}
			<Dialog
				maxWidth="sm"
				open={true}
				onClose={() => navigate("/aifabric/create")}
			>
				<form onSubmit={handleSubmit(onSuccess, onError)}>
					<DialogTitle>Connect to AWS</DialogTitle>
					<DialogContent style={{ overflow: "hidden" }}>
						<Stack spacing={2}>
							To grant Stateless permissions in your AWS account:
							<ol>
								<li>
									<MuiLink
										component={Link}
										target="_blank"
										to="https://console.aws.amazon.com/cloudformation/?pg=ln&cp=bn"
									>
										Sign in
									</MuiLink>
									&nbsp;to your AWS account and open the AWS CloudFormation
									console.
								</li>
								<li>
									Choose 'Create stack' and specify 'With new resources
									(standard).'
								</li>
								<li>
									<MuiLink
										component={Link}
										download="stateless_cloudformation.json"
										to={`data:text/json;charset=utf-8,${encodeURIComponent(
											genTemplate(crypto.randomUUID()),
										)}`}
									>
										Download this CloudFormation template.
									</MuiLink>
								</li>
								<li>
									Select 'Template is ready' for Prepare template and 'Upload a
									template file' for Specify template. Select 'Choose file' and
									navigate to and select the file you just downloaded.
								</li>

								<li>
									Specify a name for the stack (such as{" "}
									<code>AIFabricRoleStack</code>) and click next. As you proceed
									through the remaining steps, feel free to leave the default
									values.
								</li>
								<li>
									Submit the request and wait for the stack creation process to
									complete, then retrieve the role ARN and external ID from the
									Outputs tab and paste them below.
								</li>
							</ol>
							<p>
								<MuiLink
									component={Link}
									target="_blank"
									to="https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/cfn-console-create-stack.html"
								>
									See here
								</MuiLink>
								&nbsp;for more detailed instructions.
							</p>
							<TextFieldElement
								required
								fullWidth
								variant="standard"
								control={control}
								label="Name"
								name="name"
								autoFocus
							/>
							<TextFieldElement
								required
								fullWidth
								variant="standard"
								control={control}
								label="External Org ID"
								name="external_id"
								autoFocus
							/>
							<TextFieldElement
								required
								fullWidth
								variant="standard"
								control={control}
								label="Role ARN"
								name="role_arn"
							/>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Link to="/aifabric/create">
							<Button variant="contained" size="medium" type="button">
								Cancel
							</Button>
						</Link>
						<Button
							variant="contained"
							size="medium"
							type="submit"
							color="primary"
							disabled={!formState.isValid}
						>
							Submit
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
}
