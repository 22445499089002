import { LinearProgress } from "@mui/material";
import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { matchRoutes, useNavigate } from "react-router-dom";
import { z } from "zod";
import { appRoutes } from "../../appRoutes";
import { useAppDispatch } from "../../store";

const LoginLanding = () => {
	const { userData, userManager } = useAuth();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const path = z
		.string()
		.regex(/^\/.*/)
		.safeParse(userData?.state);
	useEffect(() => {
		if (
			// path is a string
			path.success &&
			// and it's a route we can handle
			matchRoutes(appRoutes, path.data) !== null &&
			// And it's not "login-landing", you don't want to come back here
			!path.data.includes("login-landing")
		) {
			navigate(path.data);
		} else {
			navigate("/");
		}
	}, [dispatch, path, userData, userManager, navigate]);
	return <LinearProgress />;
};

export default LoginLanding;
