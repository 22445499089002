import { Box, LinearProgress } from "@mui/material";
import {
	Outlet,
	Route,
	createRoutesFromElements,
	redirect,
} from "react-router-dom";
import Header from "./components/header";
import OcnCreateForm from "./routes/AIF/create/index.tsx";
import OcnListPage from "./routes/AIF/index/index.tsx";
import SuccessPage from "./routes/AIF/success/index.tsx";
import LoginLanding from "./routes/login-landing/index.tsx";
import AwsAccountSetupModal from "./components/awsAccountSetupModal.tsx";
import Account from "./components/account.tsx";

// This is kinda-dumb boilerplate

export const appRoutes = createRoutesFromElements(
	<Route
		path="/"
		element={
			<>
				<Header />
				{/* "outlet" means "render my child routes here" */}
				<Box>
					<Outlet />
				</Box>
			</>
		}
	>
		<Route
			index
			loader={() => redirect(`/aifabric${window.location.search}`)}
		/>
		<Route path="/login-landing" element={<LoginLanding />}></Route>
		<Route path="/account" element={<Account />}></Route>
		<Route path="/login" element={<LinearProgress />}></Route>
		<Route path="/aifabric">
			<Route index element={<OcnListPage />}></Route>
			<Route path="/aifabric/create" element={<OcnCreateForm />}></Route>
			<Route
				path="/aifabric/create/add-aws-account"
				element={<AwsAccountSetupModal />}
			></Route>
			<Route path="/aifabric/success" element={<SuccessPage />}></Route>
		</Route>
	</Route>,
);
