import React, { useState } from "react";
import {
	AppBar,
	Box,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Divider,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { StatelessColors } from "../theme";
import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/stateless-mint-and-black.png";
import { version } from "../../package.json";

export default function Header() {
	const auth = useAuth();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logout = () => {
		console.log("Logging Out");
		auth.signOutRedirect();
		handleClose();
	};

	const goToAccount = () => {
		navigate("/account");
		handleClose();
	};

	return (
		<AppBar
			component="nav"
			sx={{ bgcolor: StatelessColors.white }}
			style={{
				position: "relative",
				zIndex: 1301,
				paddingRight: 0,
				overflow: "auto",
			}}
		>
			<Toolbar variant="dense">
				<img src={logo} alt="Stateless" width="150" />
				<Box sx={{ flexGrow: 1 }}>
					<Typography
						color="primary"
						variant="h5"
						component="div"
						style={{ paddingLeft: "20px" }}
					>
						AI Fabric
					</Typography>
				</Box>
				<Box sx={{ flexGrow: 0 }}>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						onClick={handleMenu}
					>
						<AccountCircle
							sx={{ fontSize: 45, color: StatelessColors.primary }}
						/>
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						sx={{ zIndex: 1400 }}
					>
						<MenuItem onClick={goToAccount}>
							<Typography variant="body2">Account</Typography>
						</MenuItem>
						<Divider />
						<MenuItem onClick={logout}>
							<Typography variant="body2">Logout</Typography>
						</MenuItem>
						<Divider />
						<Box sx={{ padding: 1, textAlign: "center" }}>
							<Typography
								variant="caption"
								color="textSecondary"
								sx={{ fontWeight: "bold" }} // Bold version text
							>
								Version {version}
							</Typography>
						</Box>
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	);
}
