import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ocnApiClient, Status } from "../api";
import { RootState, useAppDispatch, useAppSelector } from ".";
import { useEffect } from "react";
import { isPresent } from "../utils";

type VPCState = {
	status: Status;
	list: Awaited<ReturnType<typeof ocnApiClient.listVpcs>>;
	fetched: boolean;
	error?: string;
};
type VpcFetchInput = Parameters<typeof ocnApiClient.listVpcs>["0"]["queries"];

const initialState: VPCState = {
	status: "idle",
	fetched: false,
	list: [],
};

export const vpcThunk = createAsyncThunk.withTypes<{
	state: RootState;
}>();

export const fetchVpcs = vpcThunk(
	"fetchVpcs",
	async (args: VpcFetchInput, { getState }) => {
		const jwt = getState().login.jwt;
		return ocnApiClient.listVpcs({
			queries: args,
			headers: { Authorization: `Bearer ${jwt}` },
		});
	},
);

export const VpcSlice = createSlice({
	name: "vpc",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchVpcs.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchVpcs.fulfilled, (state, response) => {
				state.status = "idle";
				state.fetched = true;
				if (response.payload) {
					state.list = response.payload;
				}
			})
			.addCase(fetchVpcs.rejected, (state, response) => {
				state.status = "idle";
				state.fetched = true;
				state.error = response.error.message;
			});
	},
});
export default VpcSlice.reducer;

export const useVpcs = (account: VpcFetchInput | undefined): VPCState => {
	const currentState = useAppSelector((state) => state.vpc);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (
			// TODO: check that the stored ID is the one we're asking for
			isPresent(account) &&
			!currentState.fetched &&
			currentState.status === "idle"
		) {
			console.log("dispatching Fetch VPCs API call");
			dispatch(fetchVpcs(account));
		}
	}, [dispatch, currentState, account]);

	return currentState;
};
