import { decodeJwt } from "jose";

function isEmpty(obj: unknown) {
	if (typeof obj !== "object") {
		false;
		return;
	}
	if (!obj) {
		return false;
	}
	if (Object.keys(obj).length == 0) {
		return false;
	}
	return true;
}

export const isRoleIdInJWT = (jwt: string, projectId: string) => {
	/* Check if there is a role field in the JWT. **/
	const decodedJwt = decodeJwt(jwt);
	const roleKey = `urn:zitadel:iam:org:project:${projectId}:roles`;
	if (Object.keys(decodedJwt).findIndex((x) => x === roleKey) === -1) {
		return false;
	} else {
		if (isEmpty(decodedJwt[roleKey])) {
			return false;
		}
	}
	return true;
};
