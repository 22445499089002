import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider, AuthProviderProps } from "oidc-react";
import { Provider as StoreProvider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import store from "./store";
import { Stateless } from "./theme.ts";

import JwtManager from "./components/JwtManager";

import { appRoutes } from "./appRoutes.tsx";

const queryClient = new QueryClient();

const authSettings: AuthProviderProps = {
	authority: import.meta.env.VITE_AUTH_ISSUER_URL,
	clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
	responseType: "code",
	redirectUri: window.location.origin + "/login-landing",
	scope:
		"openid profile offline_access urn:zitadel:iam:org:project:id:zitadel:aud urn:zitadel:iam:org:projects:roles",
	onBeforeSignIn() {
		return window.location.pathname + window.location.search;
	},
	onSignIn() {
		console.log("Logged in.");
	},
};

const App = () => (
	<AuthProvider {...authSettings}>
		<StoreProvider store={store}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={Stateless}>
					<JwtManager>
						<CssBaseline />
						<RouterProvider router={createBrowserRouter(appRoutes)} />
					</JwtManager>
				</ThemeProvider>
			</QueryClientProvider>
		</StoreProvider>
	</AuthProvider>
);
export default App;
