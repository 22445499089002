import {
	Card,
	CardContent,
	Grid,
	Typography,
	Link as MuiLink,
} from "@mui/material";
import background from "../../../assets/images/backgrounds/BW_denis-degioanni-2048x1364.jpeg";
import logo from "../../../assets/stateless-mint-and-black.png";
import { Link, useNavigate } from "react-router-dom";

const SUCCESS_PAGE_TIME_BEFORE_REDIRECT = 1000 /*ms*/ * 30; /*s*/

export default function SuccessPage() {
	const navigate = useNavigate();
	const navigateTimeout = setTimeout(() => {
		console.log("redirectin!");
		navigate("..");
	}, SUCCESS_PAGE_TIME_BEFORE_REDIRECT);

	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{
				minHeight: "100vh",
				backgroundImage: `url(${background})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				position: "absolute",
				left: 0,
				top: 0,
			}}
		>
			<Card sx={{ padding: "20px 30px" }}>
				<CardContent>
					<img src={logo} alt="Stateless" width="70%" />
					<Typography variant="h6">We're on it!</Typography>
					We are doing the thing... get excited.
					{Array(5).fill(<br />)}
					<Typography fontSize={"0.8rem"}>
						Taking you to the{" "}
						<MuiLink
							component={Link}
							to="../"
							onClick={() => {
								clearTimeout(navigateTimeout);
							}}
						>
							list
						</MuiLink>{" "}
						shortly...
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
}
