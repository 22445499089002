import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ocnApiClient as aifApiClient, Status } from "../api";
import { RootState, useAppDispatch, useAppSelector } from ".";
import { useEffect } from "react";
import { Accounts } from "@stateless/aif-api";
import { isAxiosError } from "axios";
import { NotOnboardedError } from "@stateless/aif-api/dist/ocnNetworks";

export const subscriptionPageUrl =
	"https://aws.amazon.com/marketplace/pp/prodview-we2sbybkwjjoi?sr=0-1&ref_=beagle&applicationId=AWS-Marketplace-Console";

type AwsAccountsState = {
	status: Status;
	list: Awaited<
		ReturnType<typeof aifApiClient.listAwsExternalAccounts>
	>["data"];
	fetched: boolean;
	error?: string;
};

const initialState: AwsAccountsState = {
	status: "idle",
	fetched: false,
	list: [],
};

export const awsAccountsThunk = createAsyncThunk.withTypes<{
	state: RootState;
}>();

export const fetchAwsAccounts = awsAccountsThunk(
	"fetchAwsAccounts",
	async (_, { getState }) => {
		const jwt = getState().login.jwt;
		try {
			const res = await aifApiClient.listAwsExternalAccounts({
				headers: { Authorization: `Bearer ${jwt}` },
			});
			return res;
		} catch (e) {
			if (isAxiosError(e)) {
				if (e.response?.status === 400) {
					if (e.response?.data?.message === NotOnboardedError) {
						console.error("Non onboarded user, redirecting");
						window.location.href = subscriptionPageUrl;
					}
				}
			}
			throw e;
		}
	},
);

type createArgs = {
	account: Omit<Accounts.AwsExternal.Create, "organization">;
	onSuccess?: (created: Accounts.AwsExternal.Get) => void;
	onError?: () => void;
};

export const CreateAwsAccount = createAsyncThunk.withTypes<{
	state: RootState;
}>()(
	"createAwsAccount",
	async ({ account, onSuccess }: createArgs, { getState }) => {
		const jwt = getState().login.jwt;
		return aifApiClient
			.createAwsExternalAccount(account, {
				headers: { Authorization: `Bearer ${jwt}` },
			})
			.then(onSuccess);
	},
);

export const AwsAccountsSlice = createSlice({
	name: "awsAccounts",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAwsAccounts.pending, (state) => {
				state.status = "loading";
				state.error = undefined;
			})
			.addCase(fetchAwsAccounts.fulfilled, (state, response) => {
				state.status = "idle";
				state.fetched = true;
				state.error = undefined;
				if (response.payload) {
					state.list = response.payload.data;
				}
			})
			.addCase(fetchAwsAccounts.rejected, (state, response) => {
				state.status = "idle";
				state.fetched = true;
				state.error = response.error.message;
			})
			.addCase(CreateAwsAccount.pending, (state) => {
				state.status = "loading";
				state.error = undefined;
			})
			.addCase(CreateAwsAccount.fulfilled, (state) => {
				state.status = "idle";
				state.error = undefined;
			})
			.addCase(CreateAwsAccount.rejected, (state, response) => {
				state.status = "idle";
				state.error = response.error.message;
			});
	},
});
export default AwsAccountsSlice.reducer;

export const useAwsAccounts = () => {
	const currentState = useAppSelector((state) => state.awsAccounts);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (
			// TODO: check that the stored ID is the one we're asking for
			!currentState.fetched &&
			currentState.status === "idle"
		) {
			console.log("dispatching Network api call");
			dispatch(fetchAwsAccounts());
		}
	}, [dispatch, currentState]);

	return currentState;
};
