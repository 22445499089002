import React, { useEffect } from "react";

const Account: React.FC = () => {
	useEffect(() => {
		window.location.href = `${
			import.meta.env.VITE_AUTH_ISSUER_URL
		}/ui/console/users/me?id=general`;
	}, []);

	return (
		<div className="account-container">
			<p className="account-text">Redirecting to account management ...</p>
			<style>{`
				.account-container {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100vh;
					background-color: #f9f9f9;
				}
				.account-text {
					font-size: 18px;
					color: #333;
				}
			`}</style>
		</div>
	);
};

export default Account;
