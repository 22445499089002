import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from ".";
import { Status, ocnApiClient } from "../api";
import { isPresent } from "../utils";

type SubnetState = {
	status: Status;
	error?: string;
	forVpc: Record<string, Awaited<ReturnType<typeof ocnApiClient.listSubnets>>>;
};
type SubnetFetchInput = Parameters<
	typeof ocnApiClient.listSubnets
>["0"]["queries"];

const initialState: SubnetState = {
	status: "idle",
	forVpc: {},
};

export const getSubnets = createAsyncThunk.withTypes<{
	state: RootState;
}>()("fetchSubnets", async (args: SubnetFetchInput, { getState }) => {
	const jwt = getState().login.jwt;
	const result = await ocnApiClient.listSubnets({
		queries: args,
		headers: { Authorization: `Bearer ${jwt}` },
	});
	return { vpc: args.vpc_id, subnets: result };
});

export const SubnetSlice = createSlice({
	name: "Subnet",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSubnets.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getSubnets.fulfilled, (state, response) => {
				state.status = "idle";
				if (response.payload) {
					state.forVpc[response.payload.vpc] = response.payload.subnets;
				}
			})
			.addCase(getSubnets.rejected, (state, response) => {
				state.status = "idle";
				state.error = response.error.message;
			});
	},
});
export default SubnetSlice.reducer;

export const useSubnets = (
	vpc_id: string | undefined,
	region: string | undefined,
	role_arn: string | undefined,
	external_id: string | undefined,
): SubnetState => {
	const currentState = useAppSelector((state) => state.subnet);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (
			isPresent(vpc_id) &&
			isPresent(region) &&
			isPresent(role_arn) &&
			isPresent(external_id) &&
			!isPresent(currentState.forVpc[vpc_id])
		) {
			dispatch(
				getSubnets({
					vpc_id,
					region,
					role_arn,
					external_id,
				}),
			);
		}
	}, [currentState.forVpc, dispatch, vpc_id, region, role_arn, external_id]);

	return currentState;
};
