import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { FieldErrors, MultiSelectElement, useForm } from "react-hook-form-mui";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../store";
import { setServices, stepBack, stepForward } from "../store/aifCreationForm";
import { useServiceList } from "../store/services";

const FormSchema = z.object({
	services: z.string().array().min(1),
});
type FormSchema = z.infer<typeof FormSchema>;

export default function SimpleServicesSetupSelect() {
	const { services, status } = useServiceList();

	const dispatch = useAppDispatch();
	const storedSvcs = useAppSelector(
		(store) => store.ocnCreationForm.servicesSetup?.services ?? [],
	).map((s) => s.id);

	const stored: FormSchema | undefined = {
		services: storedSvcs,
	};

	const { handleSubmit, control, formState } = useForm({
		resolver: zodResolver(FormSchema),
		defaultValues: stored,
	});

	const onSuccess = (formData: FormSchema) => {
		dispatch(
			setServices({
				services: formData.services.map((svcId) => ({ id: svcId })),
			}),
		);
		dispatch(stepForward());
	};
	const onError = (err: FieldErrors<FormSchema>) => {
		console.error(err);
	};

	return (
		<form onSubmit={handleSubmit(onSuccess, onError)}>
			<Stack spacing={2}>
				{status === "loading" ? (
					<CircularProgress />
				) : (
					<MultiSelectElement
						fullWidth
						autoFocus
						showChips
						variant="standard"
						label="Service"
						name="services"
						control={control}
						options={services.map((svc) => ({
							id: svc.id,
							label: `${svc.name} (${svc.cloud}, ${svc.region}, on port ${svc.port})`,
						}))}
					/>
				)}
				<Stack direction={"row"} spacing={2}>
					<Button
						variant="contained"
						size="medium"
						type="submit"
						disabled={!formState.isValid}
					>
						Next
					</Button>
					<Button
						onClick={() => dispatch(stepBack())}
						variant="outlined"
						size="medium"
						color="secondary"
					>
						Back
					</Button>
				</Stack>
			</Stack>
		</form>
	);
}
