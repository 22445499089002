import { useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import {
	FieldErrors,
	MultiSelectElement,
	SelectElement,
	useForm,
} from "react-hook-form-mui";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../store";
import { setVpc, stepBack, stepForward } from "../store/aifCreationForm";
import { useSubnets } from "../store/subnets";
import { useVpcs } from "../store/vpc";
import { isPresent } from "../utils";
import { useOcnHooks } from "../api";

const FormSchema = z.object({
	vpc_id: z.string().min(1),
	subnet_ids: z.string().min(1).array(),
});
type FormSchema = z.infer<typeof FormSchema>;

export default function VpcSetupForm() {
	const dispatch = useAppDispatch();
	const storedVpcDetails = useAppSelector(
		(store) => store.ocnCreationForm.vpcSetup,
	);
	const storedAccount = useAppSelector(
		(store) => store.ocnCreationForm.accountSetup,
	);
	const { handleSubmit, control, formState, watch } = useForm({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			vpc_id: storedVpcDetails?.vpc.id ?? "",
			subnet_ids: storedVpcDetails?.subnets.map(({ id }) => id) ?? [],
		},
	});
	const values = watch();
	const { list: accountList } = useAppSelector((state) => state.awsAccounts);
	const accountId = storedAccount?.id;
	const accountDetails = accountId
		? accountList.find((account) => account.id === accountId)
		: undefined;
	const {
		status: vpcStatus,
		list: vpcList,
		error: vpcError,
	} = useVpcs(accountDetails);
	const { useListNetworks } = useOcnHooks();
	const { data: networks } = useListNetworks();
	const unDestroyedNetworkIds = useMemo(
		() =>
			networks?.data
				?.filter((network) => network.state !== "Destroyed")
				?.map((network) => network.vpc.id) ?? [],
		[networks?.data],
	);
	const vpcOptions = vpcList?.map((vpc) => ({
		id: vpc.id,
		label: `${vpc.name} (${vpc.id}, ${vpc.cidr}, in ${vpc.region})`,
		disabled: unDestroyedNetworkIds?.includes(vpc.id),
	}));
	const vpcRegion = vpcList.find((vpc) => values.vpc_id === vpc.id)?.region;
	const { status: subnetStatus, forVpc } = useSubnets(
		values.vpc_id,
		vpcRegion,
		accountDetails?.role_arn,
		accountDetails?.external_id,
	);
	const subnetOptions = (forVpc[values.vpc_id] ?? []).map((subnet) => ({
		id: subnet.id,
		label: `${subnet.name} (${subnet.id}, ${subnet.cidr}, in ${subnet.availabilityZone})`,
	}));

	const onSuccess = (formData: FormSchema) => {
		if (isPresent(vpcRegion)) {
			dispatch(stepForward());
			dispatch(
				setVpc({
					vpc: { id: formData.vpc_id },
					subnets: formData.subnet_ids.map((id) => ({ id })),
					onramp_region: vpcRegion,
				}),
			);
		}
	};
	const onError = (err: FieldErrors<FormSchema>) => {
		console.error(err);
	};

	return (
		<form onSubmit={handleSubmit(onSuccess, onError)}>
			<Stack spacing={2}>
				{vpcError}
				{vpcStatus === "loading" ? (
					<CircularProgress />
				) : (
					<>
						<SelectElement
							autoFocus
							fullWidth
							required
							variant="standard"
							control={control}
							label="VPC ID"
							name="vpc_id"
							options={vpcOptions}
						/>
						{isPresent(values.vpc_id) &&
							isPresent(vpcRegion) &&
							(subnetStatus === "loading" ? (
								<CircularProgress />
							) : (
								<MultiSelectElement
									fullWidth
									control={control}
									variant="standard"
									label="Subnets in VPC"
									name="subnet_ids"
									showChips
									options={subnetOptions}
								/>
							))}
					</>
				)}
				<Stack direction={"row"} spacing={2}>
					<Button
						disabled={!formState.isValid}
						variant="contained"
						size="medium"
						type="submit"
					>
						Next
					</Button>
					<Button
						onClick={() => dispatch(stepBack())}
						variant="outlined"
						size="medium"
						color="secondary"
					>
						Back
					</Button>
				</Stack>
			</Stack>
		</form>
	);
}
