import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { UserManager } from "oidc-react";
import { ocnApiClient } from "../api";

type LoginState = {
	jwt: string;
	status: string;
	error: string;
};

const initialState: LoginState = {
	jwt: "",
	status: "idle",
	error: "",
};

type FinishOnboardArgs = {
	onboard_session_id: string;
	userManager: UserManager;
	path: string;
};
export const finishOnboard = createAsyncThunk.withTypes<{
	state: RootState;
}>()(
	"finishOnboard",
	async (
		{ onboard_session_id, userManager, path }: FinishOnboardArgs,
		{ getState },
	) => {
		const jwt = getState().login.jwt;
		if (jwt) {
			await ocnApiClient.finishAwsOnboard(
				{ onboard_session_id },
				{
					headers: { Authorization: `Bearer ${jwt}` },
				},
			);
			await userManager.revokeTokens(["access_token"]);
			await userManager.signinSilent();
			window.location.href = path;
		}
	},
);

export const LoginSlice = createSlice({
	name: "Login",
	initialState,
	reducers: {
		storeJwt(state: LoginState, action: PayloadAction<string>) {
			state.jwt = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(finishOnboard.pending, (state) => {
				state.status = "loading";
			})
			.addCase(finishOnboard.fulfilled, (state) => {
				state.status = "idle";
			})
			.addCase(finishOnboard.rejected, (state, response) => {
				state.status = "idle";
				state.error = "Error with onboarding.";
				console.log("finishOnboard error", state, response);
			});
	},
});

export const { storeJwt } = LoginSlice.actions;
export default LoginSlice.reducer;
