import {
	InternalAxiosRequestConfig,
	AxiosResponse,
	AxiosInstance,
} from "axios";

// Helper function to validate mock data using Zod schema and get expected status code
export const validateMockData = (
	url: string,
	data: object,
	apiSpecs: object,
): { isValid: boolean; expectedStatus: number } => {
	// Loop through API specs and find the matching schema based on the URL
	for (const [apiUrl, spec] of Object.entries(apiSpecs)) {
		if (url.includes(apiUrl)) {
			// Find the matching endpoint based on the method and path
			const matchingEndpoint = spec.find((endpoint: { path: string }) =>
				url.includes(endpoint.path),
			);

			if (matchingEndpoint && matchingEndpoint.response) {
				const successResponseSchema = matchingEndpoint.response; // Get the response schema
				const expectedStatus = matchingEndpoint.status || 200; // Default to 200 if no status is specified

				try {
					// Validate the mock data using the response schema
					successResponseSchema.parse(data);

					return { isValid: true, expectedStatus }; // If validation passes, return true and the expected status
				} catch (error) {
					console.error("Mock data validation failed:", error);

					return { isValid: false, expectedStatus }; // Return false and the expected status if validation fails
				}
			}
		}
	}

	return { isValid: false, expectedStatus: 200 }; // Default to 200 if no schema is found
};

// Mock Axios response to include status, statusText, etc.
export const createMockResponse = <T>(
	data: T,
	config: InternalAxiosRequestConfig,
	status: number,
): AxiosResponse<T> => {
	return {
		data,
		status,
		statusText:
			status === 201 ? "Created" : status === 204 ? "No Content" : "OK",
		headers: {},
		config,
	};
};

// Interceptor function to use mock data and handle different status codes
export const interceptAxiosResponseForMockData = (
	configuredInstance: AxiosInstance,
	mockDataMap: object,
	apiSpecs: object,
) => {
	configuredInstance.interceptors.response.use((response) => {
		const url = response.config.url || "";
		// Find the matching mock data for the requested URL
		const mockResponseData = Object.keys(mockDataMap).find(
			(apiUrl) => url?.includes(apiUrl),
		);

		// If mock data is found, validate it before returning
		if (mockResponseData) {
			const data = mockDataMap[mockResponseData as keyof typeof mockDataMap];
			const { isValid, expectedStatus } = validateMockData(url, data, apiSpecs);

			if (isValid) {
				// Return the mock response with the expected status code if validation passes
				return Promise.resolve(
					createMockResponse(data, response.config, expectedStatus),
				);
			} else {
				// Log the error or throw an exception if validation fails
				console.error("Invalid mock data for URL:", url);

				return Promise.reject(new Error(`Invalid mock data for URL: ${url}`));
			}
		}

		// If no mock data found, return the actual response
		return response;
	});
};
