import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Box,
	Button,
	IconButton,
	Chip,
	Grid,
	LinearProgress,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Link as MuiLink,
	Typography,
	TablePagination,
} from "@mui/material";
import { OcnNetworks as AifNetworks } from "@stateless/aif-api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useOcnHooks } from "../../../api";
import { isPresent } from "../../../utils";
import DoubleConfirmButton from "../../../components/doubleConfirmButton";
import {
	deleteAifNetwork,
	redeployAifNetwork,
} from "../../../store/aifNetwork";
import { useAppDispatch } from "../../../store";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { StatelessColors } from "../../../theme";
import { isAxiosError } from "axios";
import { subscriptionPageUrl } from "../../../store/awsAccounts";
import { NotOnboardedError } from "@stateless/aif-api/dist/ocnNetworks";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// type StatFact = {
// 	name: string;
// 	data: number;
// 	label?: string;
// };

const stateToColor: Record<
	AifNetworks.Get["state"],
	Parameters<typeof Chip>["0"]["color"]
> = {
	Deployed: "success",
	Deploying: "info",
	Destroyed: "default",
	Destroying: "warning",
	Error: "error",
	Initial: "default",
};

const ServiceTable = ({ network }: { network: AifNetworks.Get }) => {
	return (
		<>
			<Typography variant="h6">Services</Typography>
			<TableContainer component={Paper} sx={{ maxHeight: 225 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Service</TableCell>
							<TableCell>Port</TableCell>
							<TableCell>CSP</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{network.services.map((service) => (
							<TableRow
								key={service.id}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell>{service.name}</TableCell>
								<TableCell>
									{service.port}
									<IconButton
										color="secondary"
										aria-label="copy"
										onClick={() =>
											navigator.clipboard.writeText(service.port.toString())
										}
									>
										<ContentCopyIcon fontSize="small" />
									</IconButton>
								</TableCell>
								<TableCell>{service.cloud}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

const SubnetTable = ({ network }: { network: AifNetworks.Get }) => {
	return (
		<>
			<Typography variant="h6">Subnets</Typography>
			<TableContainer component={Paper} sx={{ maxHeight: 225 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>CIDR</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{network.subnets.map((subnet) => (
							<TableRow
								key={subnet.id}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell>
									{subnet.id}
									<IconButton
										color="secondary"
										aria-label="copy"
										onClick={() => navigator.clipboard.writeText(subnet.id)}
									>
										<ContentCopyIcon fontSize="small" />
									</IconButton>
								</TableCell>
								<TableCell>{subnet.cidr}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

const AifListPage = () => {
	const { useListNetworks } = useOcnHooks();
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const {
		data: networks,
		isLoading,
		error,
		invalidate,
		isError,
	} = useListNetworks({
		queries: {
			limit: rowsPerPage,
			offset: page * rowsPerPage,
		},
	});
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	// const stats: Array<StatFact> = [
	// 	{
	// 		name: "Active Apps",
	// 		data: 6,
	// 	},
	// 	{
	// 		name: "Connected VPCs",
	// 		data: 4,
	// 	},
	// 	{
	// 		name: "Active VPCs",
	// 		data: 2,
	// 	},
	// 	{
	// 		name: "Avg Daily Transfer",
	// 		data: 41,
	// 		label: "MB",
	// 	},
	// 	{
	// 		name: "MTD Transfer",
	// 		data: 233,
	// 		label: "MB",
	// 	},
	// ];
	const undestroyedNetworks = useMemo(
		() =>
			networks?.data?.filter((network) => network.state !== "Destroyed") ?? [],
		[networks?.data],
	);
	const paginatedUndestroyedNetworks = useMemo(() => {
		const start = page * rowsPerPage;
		const end = start + rowsPerPage;

		return undestroyedNetworks.slice(start, end);
	}, [undestroyedNetworks, page, rowsPerPage]);

	const handlePageChange = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleRowsPerPageChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0); // Reset to first page when changing rows per page
	};

	// Handle delete action
	const handleDelete = async (networkId: string) => {
		await dispatch(
			deleteAifNetwork({
				networkId,
				onSuccess: invalidate,
			}),
		);

		invalidate(); // Refresh the network list after successful deletion
	};

	useEffect(() => {
		if (isPresent(networks?.data) && undestroyedNetworks.length < 1) {
			navigate("/aifabric/create");
		}
	}, [undestroyedNetworks, navigate, networks?.data]);

	if (isError) {
		if (isAxiosError(error)) {
			if (error.response?.status === 400) {
				if (error.response?.data.message === NotOnboardedError) {
					console.log("not onboarded, redirecting to marketplace");
					window.location.href = subscriptionPageUrl;
				}
			}
		}
	}

	if (isLoading) {
		return <LinearProgress />;
	} else if (error) {
		return <Alert severity="error">{error.message}</Alert>;
	} else if (isPresent(networks?.data)) {
		return (
			<>
				{/* {displayStats && (
					<Paper elevation={2} sx={{ m: 10, p: 5 }}>
						<Stack
							direction="row"
							justifyContent="space-around"
							alignItems="center"
						>
							{stats.map((stat) => (
								<Box key={stat.name}>
									<Typography variant="h3">
										{stat.data} {stat.label}
									</Typography>
									<Typography variant="h6">{stat.name}</Typography>
								</Box>
							))}
						</Stack>
					</Paper>
				)} */}
				<br />
				<br />
				<br />
				<Stack spacing={2} mx={{ xs: 2, md: 10, lg: 20 }}>
					<Link to="./create">
						<Button variant="contained" type="button" sx={{ width: 200 }}>
							Add New VPC
						</Button>
					</Link>
					{paginatedUndestroyedNetworks.length < 1 ? (
						<Typography variant="h3" color={StatelessColors.tertiary}>
							No VPCs to Display.
						</Typography>
					) : (
						paginatedUndestroyedNetworks.map((network) => (
							<Accordion key={network.id}>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="h6" mr={2}>
												VPC:
											</Typography>
											<Typography variant="h6" color="secondary">
												{network.vpc.name
													? `${network.vpc.name}`
													: network.vpc.id}
											</Typography>
										</AccordionSummary>

										<Typography variant="h6" mr={2}>
											ID:
										</Typography>
										<Typography variant="h6" color="secondary">
											{network.vpc.id}
										</Typography>
										<IconButton
											color="secondary"
											aria-label="copy"
											onClick={() =>
												navigator.clipboard.writeText(network.vpc.id)
											}
										>
											<ContentCopyIcon />
										</IconButton>
									</Stack>
									<Box m={2}>
										{network?.organization?.subscription_details?.active ==
										false ? (
											<Chip
												color="warning"
												label="Status: Unsubscribed"
												sx={{ mr: 2 }}
											/>
										) : (
											<Chip
												color={stateToColor[network.state]}
												label={`Status: ${network.state}`}
												sx={{ mr: 2 }}
											/>
										)}
										<DoubleConfirmButton
											dialog={["Delete", "Are You Sure?", "Deleting..."]}
											action={() => handleDelete(network.id)}
										/>
										{network.state === "Error" && (
											<>
												<DoubleConfirmButton
													dialog={[
														"Redeploy",
														"Are You Sure?",
														"Redeploying...",
													]}
													action={() => {
														dispatch(
															redeployAifNetwork({
																networkId: network.id,
															}),
														);
														setTimeout(invalidate, 100);
													}}
													sx={{ ml: 2 }}
												/>
												<DoubleConfirmButton
													dialog={["Delete", "Are You Sure?", "Deleting..."]}
													action={() => {
														dispatch(
															deleteAifNetwork({
																networkId: network.id,
																onSuccess: invalidate,
															}),
														);
														setTimeout(invalidate, 100);
													}}
													sx={{ ml: 2 }}
												/>
											</>
										)}
									</Box>
								</Stack>
								<AccordionDetails>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<ServiceTable network={network} />
										</Grid>
										<Grid item xs={6}>
											<SubnetTable network={network} />
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						))
					)}
					<TablePagination
						component="div"
						count={undestroyedNetworks.length} // Use the length of undestroyed networks
						page={page}
						onPageChange={handlePageChange}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleRowsPerPageChange}
						rowsPerPageOptions={[5, 10, 15, 20]} // Restrict options to a maximum of 20
					/>
					<Stack
						sx={{ mt: "200px" }}
						spacing={2}
						mx={{ xs: 2, md: 10, lg: 20 }}
					>
						<Accordion>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="h6" mr={2}>
										SET UP GCP CONVERSATIONAL AI
									</Typography>
								</AccordionSummary>
							</Stack>
							<AccordionDetails>
								<Typography color="secondary" sx={{ mb: "16px" }}>
									<b>Please note:</b> Your network must reach the 'deployed'
									state before you can proceed.
								</Typography>
								<Typography variant="h6" color="secondary" sx={{ mb: "16px" }}>
									Download{" "}
									<MuiLink
										component={Link}
										to="https://aif-tools.stateless.net/notebooks/intro-grounding-gemini.ipynb"
										target="_blank"
									>
										this notebook
									</MuiLink>{" "}
									to set up your Vertex AI chatbot.
								</Typography>
								<Typography variant="h6" color="secondary">
									See the{" "}
									<MuiLink
										component={Link}
										to="https://stateless-ai-fabric.readme.io/docs/getting-started"
										target="_blank"
									>
										{" "}
										Quick Start guide
									</MuiLink>{" "}
									for additional information.
								</Typography>
							</AccordionDetails>
						</Accordion>
						{/* <Accordion>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="h6" mr={2}>
									GCP RESEARCH
								</Typography>
							</AccordionSummary>
						</Stack>
						<AccordionDetails>
							<Typography variant="h6" color="secondary" sx={{ mb: "16px" }}>
								Placeholder for second use case.
							</Typography>
						</AccordionDetails>
					</Accordion> */}
					</Stack>
				</Stack>
			</>
		);
	}
};

export { ServiceTable, SubnetTable };
export default AifListPage;
