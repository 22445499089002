import { configureStore } from "@reduxjs/toolkit";
import {
	TypedUseSelectorHook,
	useDispatch as rootUseDispatch,
	useSelector as rootUseSelector,
} from "react-redux";

import login from "./login";
import ocnCreationForm from "./aifCreationForm";
import vpc from "./vpc";
import subnet from "./subnets";
import services from "./services";
import awsAccounts from "./awsAccounts";

const store = configureStore({
	reducer: {
		ocnCreationForm,
		vpc,
		subnet,
		services,
		login,
		awsAccounts,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = rootUseDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = rootUseSelector;
