import { useAuth } from "oidc-react";
import { ReactElement, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { finishOnboard, storeJwt } from "../store/login";
import { useAppDispatch } from "../store";
import { z } from "zod";
import { isRoleIdInJWT } from "../routes/login-landing/utils";

const PROJECT_ID = import.meta.env.VITE_ZITADEL_PROJECT_ID;
if (!PROJECT_ID) {
	throw new Error("VITE_ZITADEL_PROJECT_ID missing from the env");
}

/**
 * JWT Manager just keeps the token from oidc-react in sync with the one in the store,
 * so actions like API requests can easily access it.
 * We could make this just provide the zodiosClient?
 */

const JwtManager = ({
	children,
}: {
	children: Array<ReactElement> | ReactElement;
}) => {
	const jwt = useAuth().userData?.access_token ?? "";
	const dispatch = useAppDispatch();
	const { userData, userManager } = useAuth();

	useEffect(() => {
		if (jwt) {
			dispatch(storeJwt(jwt));
		}
		const path = z
			.string()
			.regex(/^\/.*/)
			.safeParse(userData?.state);

		if (path.success) {
			const myURL = new URL(`https://example.com${path.data}`); // I only care about query params but I want to use url parser, so adding fake prefix
			const cmd = myURL.searchParams.get("cmd");
			if (cmd === "onboard") {
				const type = myURL.searchParams.get("type");
				if (type === "aws") {
					const onboard_session_id = myURL.searchParams.get("id");
					if (!onboard_session_id) {
						console.error(
							"Missing 'id' in query parameters (onboard sessions id)",
						);
					} else {
						const jwt = userData?.access_token;
						if (!jwt) {
							console.error("No JWT, error");
						} else {
							if (!isRoleIdInJWT(jwt, PROJECT_ID)) {
								dispatch(
									finishOnboard({
										onboard_session_id,
										userManager,
										path: path.data,
									}),
								);
							}
						}
					}
				}
			}
		}
	}, [jwt, dispatch, userData, userManager]);

	if (!jwt) {
		return <LinearProgress />;
	} else {
		return children;
	}
};

export default JwtManager;
