import { createTheme } from "@mui/material";

export const Colors = {
	teal: "#0bb89b",
	green: "#89db89",
	blue: "#157ef6",
	orange: "#f0682d",
	red: "#e13362",
	yellow: "#ffce00",
	black: "#000",
	white: "#fff",
	gray100: "#fafafa",
	gray200: "#f9f9f9",
	gray300: "#f0f0f0",
	gray400: "#ebebeb",
	gray500: "#d0d0d0",
	gray600: "#b6b6b6",
	gray700: "#919191",
	gray800: "#6e6e6e",
	gray900: "#404040",
};

export const StatelessColors = {
	primary: Colors.teal,
	secondary: Colors.gray900,
	tertiary: Colors.gray800,
	success: Colors.green,
	error: Colors.red,
	warning: Colors.yellow,
	info: Colors.blue,
	white: Colors.white,
};

export const Stateless = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: StatelessColors.primary,
		},
		secondary: {
			main: StatelessColors.secondary,
		},
		error: {
			main: StatelessColors.error,
		},
		warning: {
			main: StatelessColors.warning,
		},
		success: {
			main: StatelessColors.success,
		},
		info: {
			main: StatelessColors.info,
		},
		background: {
			default: Colors.gray300,
		},
	},
	typography: {
		fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
		allVariants: {
			color: StatelessColors.secondary,
		},
		h1: {
			color: StatelessColors.primary,
		},
		h4: {
			color: StatelessColors.primary,
			fontWeight: "bold",
		},
		h5: {
			color: StatelessColors.primary,
			fontWeight: "bold",
		},
		h6: {
			color: StatelessColors.primary,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.color === "primary" && {
						color: Colors.white,
					}),
				}),
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: StatelessColors.info,
					textDecoration: "none",
					"&:hover": {
						textDecoration: "underline",
					},
				},
			},
		},
	},
});
